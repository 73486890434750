<template>
  <div class="main_style">
    <NavBarHome v-if="navBarStyle === '1'" textColor="#000000" :iconUrl="require('@/assets/img/home/logo-2.png')"/>
    <NavBar v-else />
    <div class="header">
      <div class="header-text">
        <p class="header-title">众享Fabric产品</p>
        <p class="header-desc">
          Fabric是一个面向联盟链的区块链平台，可以通过在这个平台上部署特定的链码(Chaincode)实现业务逻辑。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">产品介绍</div>
      <div class="intro-content">
        <p>
          Fabric是一个面向联盟链的区块链平台，可以通过在这个平台上部署特定的链码(Chaincode)实现业务逻辑。为开发企业级区块链应用提供： 基于许可的区块链框架、模块化设计、智能合约、隐私与机密性、可插拔的共识、高性能与可拓展<br/><br/>2019年11月5日，Hyperledger超级账本宣布启动其超级账本认证服务供应商（HCSP）计划，获认证的成员是经过资格审核的服务供应商，他们具有成功帮助企业采用超级账本技术的深度经验。众享科技是首批完成认证并获得Hyperledger Fabric HCSP资格的公司。
        </p>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">产品架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/product/Fabric/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">产品优势</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Fabric/advantage-1.png"
                />
              </div>
              <div class="title">国密改造</div>
              <div class="text">
                众享科技对Fabric进行了国密版改造，可提供国密服务。
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Fabric/advantage-2.png"
                />
              </div>
              <div class="title">联盟治理</div>
              <div class="text">
                Fabric联盟治理是基于Fabric底层技术之上做的一组套件，可以有效解决Fabric框架本身配置复杂、使用繁琐的问题。Fabric联盟治理在Fabric之上引入了“公共仓库（公共基础链）”和“流程引擎”，实现便捷地对通道、机构、合约进行操作及管理。
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Fabric/advantage-3.png"
                />
              </div>
              <div class="title">运维平台</div>
              <div class="text">
                众享科技提供了创新的区块链一键部署平台：WisChain，WisChain支持快速搭建Fabric网络、可视化运维、多链连接及扩展。
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 相关项目 -->
    <div class="project">
      <div class="commom-title">相关项目</div>
      <div class="project-content">
        <el-row :gutter="24">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Fabric/project-1.png"
                />
              </div>
              <div class="title">中国贸易金融跨行交易区块链平台</div>
            </div></el-col
          >
					<el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Fabric/project-2.png"
                />
              </div>
              <div class="title">中信银行信用证</div>
            </div></el-col
          >
					<el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Fabric/project-3.png"
                />
              </div>
              <div class="title">苏宁“达尔文”区块链金融黑名单系统</div>
            </div></el-col
          >
        </el-row>
				<el-row :gutter="24">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Fabric/project-4.png"
                />
              </div>
              <div class="title">建总行投标保函管理平台</div>
            </div></el-col
          >
					<el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/Fabric/project-5.png"
                />
              </div>
              <div class="title">聚量集团供应链金融</div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import NavBarHome from "@/components/NavBarHome";
import Footer from "@/components/Footer";
import {scrollMixin} from '@/mixins/scroll.js'
export default {
  mixins: [scrollMixin],
  components: {
    NavBar,
    NavBarHome,
    Footer,
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/product/Fabric/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
  .project {
    .project-content {
      @extend .content-width;
      padding-bottom: 70px;
      .grid-content {
        height: 194px;
        background: #f4f7fc;
        display: flex;
        flex-direction: column;
        align-items: center;
				padding:24px 48px 0px;
        .img {
          width: 66px;
          height: 66px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 22px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
					text-align: center;
        }
      }
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    
  }
}
</style>
